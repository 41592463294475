<template>
  <div class="account-reset-password full-page flex">
    <div class="reset flex">
      <div class="form-wrapper">
        <a-form name="login" :form="form" class="form">
          <div class="flex reset-text">修改密码</div>

          <div class="input-wrapper bottom-line">
            <img src="./img/user.png" alt="" class="input-wrapper-img">
            <a-form-item label="" name="userName">
              <a-input :allow-clear="true" placeholder="用户名"
                       v-decorator="['userName', { rules: [{ required: true, message: '请输入用户名' }] }]"
                       class="reset-input" @keypress.enter="onSubmit" @change="userNameChanged"></a-input>
            </a-form-item>
          </div>

          <div class="input-wrapper bottom-line">
            <img src="./img/lock.png" alt="" class="input-wrapper-img">
            <a-form-item label="" name="password">
              <a-input :allow-clear="true"
                       type="password"
                       placeholder="请输入密码"
                       v-decorator="['password', { rules: [{ required: true, message: '请输入正确的密码' }] }]"
                       class="reset-input"
                       @keypress.enter="onSubmit"
                       @change="passwordChanged"/>
            </a-form-item>
          </div>

          <div class="input-wrapper bottom-line">
            <img src="./img/locked.png" alt="" class="input-wrapper-img">
            <a-form-item label="" name="repeatPassword">
              <a-input :allow-clear="true"
                       type="password"
                       placeholder="确认密码"
                       v-decorator="['repeatPassword', { rules: [{ required: true, message: '请确认密码' }] }]"
                       class="reset-input"
                       @keypress.enter="onSubmit"
                       @change="repeatPasswordChanged"/>
            </a-form-item>
          </div>

          <div class="input-wrapper captcha-wrapper bottom-line" style="padding-right: 140px;">
            <img src="./img/shield.png" alt="" class="input-wrapper-img">
            <a-form-item label="" name="captcha">
              <a-input :allow-clear="true" placeholder="验证码"
                       v-decorator="['captcha', { rules: [{ required: true, message: '请输入验证码' }] }]"
                       class="reset-input" @keypress.enter="onSubmit" @change="captchaChanged"></a-input>
            </a-form-item>
            <a-button type="primary" class="captcha-btn" :loading="!!captchaTimer" @click="getCaptcha">
              <span v-if="captchaTimer">{{ captchaCount }}s 后可重试</span>
              <span v-else>获取验证码</span>
            </a-button>
          </div>

          <div class="hint-msg">如您无法接收验证码，请拨打客服电话进行咨询 18742454606</div>

          <div class="input-wrapper btn-wrapper">
            <a-button type="link" size="large" html-type="button" :loading="loading"
                      class="back-btn pointer" @click="back">
              <span style="font-size: 0.8em;">返回登录</span>
            </a-button>
            <a-form-item :wrapper-col="{offset: 7, span: 10}">
              <a-button type="primary" size="large" html-type="button" block
                        :loading="loading"
                        :disabled="confirmDisabled"
                        class="login-btn" @click="onSubmit">
                <span>确认修改</span>
              </a-button>
            </a-form-item>
          </div>

        </a-form>
      </div>

      <img src="./img/brand.png" alt="" class="login-logo">
    </div>
  </div>
</template>

<script>
import { getCaptcha, resetPassword } from './api';

export default {
  name: 'ResetPassword',
  computed: {
    confirmDisabled() {
      return this.userName?.length !== 11 || this.password?.length < 6 || this.repeatPassword?.length < 6 || !this.captcha;
    },
  },
  data() {
    return {
      loading: false,
      captchaLoading: false,

      form: this.$form.createForm(this, { name: 'coordinated' }),

      userName: '',
      password: '',
      repeatPassword: '',
      captcha: '',

      captchaCount: 60,
      captchaTimer: null,
    };
  },
  methods: {
    back() {
      this.$router.replace('/account/login');
    },

    async getCaptcha() {

      this.form.validateFields(['userName'], async (err, values) => {
        if (err) {
          this.$message.info({ content: '请填写用户名' });
          return this.loading = false;
        }

        if (this.captchaLoading || this.captchaTimer) {
          return;
        }
        this.captchaLoading = true;
        const params = {
          phone: values.userName,
          type: 'change_password',
        };
        const data = await getCaptcha(params).finally(() => this.captchaLoading = false);
        if (!data || data.error_code) {
          return this.$message.error(data?.message || '验证码发送失败');
        }
        this.captchaCount = 60;
        this.captchaTimer = setInterval(() => {
          if (this.captchaCount <= 0) {
            clearInterval(this.captchaTimer);
            this.captchaTimer = null;
            return;
          }
          this.captchaCount--;
        }, 1000);
        this.$message.success(data?.message || '验证码发送成功');
      });
    },

    onSubmit() {
      this.form.validateFields((err, values) => {
        if (err) {
          this.$message.info({ content: '请填写必填项' });
          return this.loading = false;
        }

        if (values.password !== values.repeatPassword) {
          return this.$message.error('两次密码不一致');
        }

        const params = {
          phone: values.userName,
          newpassword: values.password,
          repassword: values.repeatPassword,
          code: values.captcha,
        };

        this.resetPassword(params);
      });
    },
    async resetPassword(params) {
      if (this.loading) { return Promise.reject(new Error('Loading')); }
      this.loading = true;

      const data = await resetPassword(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return this.$message.error(data?.message || '密码修改失败');
      }
      this.$message.success(data?.message || '密码修改成功');
      this.$nextTick(() => {
        this.$router.replace('/account/login');
      });
    },

    passwordChanged(evt) {
      this.password = evt.target.value || '';
    },
    userNameChanged(evt) {
      this.userName = evt.target.value || '';
    },
    repeatPasswordChanged(evt) {
      this.repeatPassword = evt.target.value || '';
    },
    captchaChanged(evt) {
      this.captcha = evt.target.value || '';
    },
  },
};
</script>

<style scoped lang="scss">
.account-reset-password {
  overflow: auto;
  position: relative;
  background-color: #FFFFFF;

  .reset {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1200px;
    height: 670px;
  }

  .form-wrapper {
    position: relative;
    width: 50%;
    height: 100%;
    background: #F1F3FC;
  }

  .form {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  .input-wrapper {
    position: relative;
    width: 60%;
    height: 72px;
    margin: 0 auto 5px;
    padding-left: 40px;
  }

  .captcha-wrapper {
    padding-right: 100px;
  }

  .captcha-btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .bottom-line {
    border-bottom: 1px solid #DDDDDD;
  }

  .input-wrapper-img {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 23px;
  }

  .reset-input {
    height: 50px;
    border: none !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
  }

  .reset-input ::v-deep .ant-input {
    height: 100%;
    padding-top: 25px;
    border: none !important;
    background-color: transparent;
    box-shadow: none !important;
    outline: none !important;
  }

  ::v-deep .ant-form-explain {
    transform: translateY(-3px);
  }

  ::v-deep .ant-input-clear-icon {
    transform: translateY(12px);
  }

  .login-logo {
    width: 50%;
  }

  .reset-text {
    margin-bottom: 25px;
    font-size: 36px;
    font-weight: 500;
    color: #5075FF;
  }

  .btn-wrapper {
    margin-top: 55px;
    padding-left: 0;
    padding-right: 0;
  }

  .back-btn {
    position: absolute;
    left: 0;
    z-index: 2;
  }

  .hint-msg {
    width: 60%;
    margin: 0 auto 5px;
    font-size: 12px;
    font-weight: 500;
    color: #83868E;
  }
}
</style>
