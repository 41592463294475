<template>
  <div class="account-index full-page">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'Index',
};
</script>

<style scoped lang="scss">
.site-gray {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
  filter: gray;
}
</style>
